import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import QuestionItems from './questionItems';
import QuestionItem from './questionItem';
import { BLUE, LIGHT_GRAY_1 } from '../../constants/colors';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP,
} from '../../constants/screens';

const Questions = ({ questions: { 0: { content, title } } }) => (
  <QuestionsContainer>
    <MainContent>
      <QuestionsTitle>
        {title}
      </QuestionsTitle>
      <QuestionItemsContainer>
        {
            content.map(({
              id, icon: { file: { localFile: { publicURL } } },
              button,
              title: qTitle,
              description: { html },
            }) => (
              <QuestionItem
                key={id}
                title={qTitle}
                text={html}
                buttonText={button.text}
                to={button.url}
                image={publicURL}
              />
            ))
          }
        <QuestionItems
          content={content}
        />
      </QuestionItemsContainer>
    </MainContent>
  </QuestionsContainer>
);

const QuestionsContainer = styled.div`
  display: flex;
  background-color: ${LIGHT_GRAY_1};
  align-items: center;
  justify-content: center;
  padding: 185px 5% 195px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 130px 5% 150px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const QuestionsTitle = styled.h4`
  font-size: 40px;
  line-height: normal;
  text-align: center;
  color: ${BLUE};
  text-transform: uppercase;
  margin-bottom: 100px;
  line-height: normal;
  max-width: 800px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 30px;
    margin-bottom: 70px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 120px;
  }
`;

const QuestionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: ${TABLET}) {
    > div:not(:first-child) {
      margin-top: 120px;
    }
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;

Questions.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default Questions;
