import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import GatsbyLink from 'gatsby-link';
import RoundedButton from '../buttons/roundedButton';
import {
  BLACK, BLUE, DARK_GRAY, TURQUOISE, WHITE,
} from '../../constants/colors';
import { LAPTOP, MOBILE, TABLET } from '../../constants/screens';
import { pageNameToSeoTagTypes } from '../../utils/seo';

const InfoSubTitleStyle = () => css`
  display: block;
  font-size: ${props => props.subTitleFontSize};
  text-transform: uppercase;
  color: ${props => props.subTitleColor};
  margin-bottom: 30px;
  letter-spacing: normal;
  line-height: normal;

  @media screen and (max-width: ${LAPTOP}) {
    font-size: 26px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const InfoTextStyle = () => css`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  font-weight: 400;
  color: ${props => props.textColor};
  max-width: 550px;
  width: 100%;
  line-height: normal;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 18px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 16px;
  }
`;

const Info = ({
  pageName,
  title,
  subTitle,
  text,
  titleColor,
  subTitleFontSize,
  subTitleColor,
  textColor,
  withButton,
  reversed,
  buttonColor,
  buttonBackground,
  buttonHoverTextColor,
  className,
  additionalSubTitle,
  onButtonClick,
  switchTitleAndSubtitle,
  buttonText,
  link,
  externalLink,
}) => {
  const InfoSubTitle = styled[pathOr('h4', [pageName, 'InfoSubTitle'], pageNameToSeoTagTypes)]`
    ${props => InfoSubTitleStyle(props)}`;

  const InfoText = styled[pathOr('p', [pageName, 'InfoText'], pageNameToSeoTagTypes)]`
    ${props => InfoTextStyle(props)}`;

  const HeadingWrapper = styled[pathOr('div', [pageName, 'HeadingWrapper'], pageNameToSeoTagTypes)]``;

  return (
    <InfoContainer reversed={reversed} className={className}>
      { !switchTitleAndSubtitle
        ? <InfoTitle titleColor={titleColor}>{title}</InfoTitle>
        : (
          <InfoSubTitle
            subTitleFontSize={subTitleFontSize}
            subTitleColor={subTitleColor}
          >
            {title}
          </InfoSubTitle>
        )
      }
      <HeadingWrapper>
        {
          additionalSubTitle && (
            <AdditionalSubTitle>
              {additionalSubTitle}
            </AdditionalSubTitle>
          )
        }
        { !switchTitleAndSubtitle
          ? (
            <InfoSubTitle
              subTitleFontSize={subTitleFontSize}
              subTitleColor={subTitleColor}
            >
              {subTitle}
            </InfoSubTitle>
          )
          : <InfoTitle titleColor={titleColor}>{subTitle}</InfoTitle>
      }
      </HeadingWrapper>
      {text && (
        <InfoText textColor={textColor} dangerouslySetInnerHTML={{ __html: text }} />
      )}
      {
      withButton
      && (
      <ButtonWrapper reversed={reversed}>
        <RoundedButton
          text={buttonText}
          color={buttonColor}
          background={buttonBackground}
          hoverTextColor={buttonHoverTextColor}
          onClick={onButtonClick}
          tag={externalLink ? 'a' : GatsbyLink}
          href={externalLink ? link : undefined}
          to={externalLink ? undefined : link}
        />
      </ButtonWrapper>
      )
    }
    </InfoContainer>
  );
};

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.reversed ? 'flex-end' : 'flex-start')};
  text-align:  ${props => (props.reversed ? 'right' : 'left')};
  
  > h4, h3, p {
    margin-top: 0;
  }
  
  @media screen and (max-width: ${TABLET}) {
    align-items: center;
    max-width: 500px;

    > div {
      text-align: center;
    }
  }
`;

export const InfoTitle = styled.h3`
  font-size: 19px;
  text-transform: uppercase;
  font-family: AvenirNextLTProBold;
  color: ${props => props.titleColor};
  margin-bottom: 20px;
  letter-spacing: normal;
  line-height: normal;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 17px;
    margin-bottom: 15px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 17px;
  }
`;

export const AdditionalSubTitle = styled.span`
  color: ${TURQUOISE};
  font-size: 40px;
  text-transform: uppercase;
  letter-spacing: normal;
  line-height: normal;
  white-space: pre-line;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    text-align: center;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: ${props => (props.reversed ? 'flex-end' : 'flex-start')};
  align-items: center;
  margin-top: 60px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-top: 30px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding-top: 30px;
    justify-content: center;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    margin-top: 45px;
  }
`;

Info.defaultProps = {
  withButton: false,
  reversed: false,
  buttonText: 'dive into data',
  textColor: BLACK,
  subTitleColor: BLUE,
  subTitleFontSize: '33px',
  titleColor: DARK_GRAY,
  buttonColor: TURQUOISE,
  buttonBackground: '',
  buttonHoverTextColor: WHITE,
  title: '',
  pageName: '',
  className: '',
  additionalSubTitle: '',
  onButtonClick: () => null,
  link: '',
  switchTitleAndSubtitle: false,
  text: '',
  externalLink: false,
};

Info.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string.isRequired,
  text: PropTypes.string,
  titleColor: PropTypes.string,
  subTitleFontSize: PropTypes.string,
  subTitleColor: PropTypes.string,
  textColor: PropTypes.string,
  withButton: PropTypes.bool,
  reversed: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonBackground: PropTypes.string,
  buttonHoverTextColor: PropTypes.string,
  pageName: PropTypes.string,
  className: PropTypes.string,
  additionalSubTitle: PropTypes.string,
  onButtonClick: PropTypes.func,
  link: PropTypes.string,
  switchTitleAndSubtitle: PropTypes.bool,
  externalLink: PropTypes.bool,
};

export default Info;
