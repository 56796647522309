import React from 'react';
import { LayoutContext } from '../components/layout';

export default function (options) {
  return function WithCustomLayoutHOC(WrappedComponent) {
    class WithCustomLayout extends React.Component {
      componentWillMount() {
        const { layoutUpdater } = this.context;

        if (layoutUpdater) {
          layoutUpdater(options);
        }
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    WithCustomLayout.contextType = LayoutContext;

    return WithCustomLayout;
  };
}
