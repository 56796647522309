import styled from 'styled-components';
import { BLUE } from '../../constants/colors';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH,
  LAPTOP, media, MOBILE, TABLET,
} from '../../constants/screens';

export const H2 = styled.h2`
  font-size: 40px;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${BLUE};
  text-transform: uppercase;
  margin-bottom: 40px;
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
     font-size: 24px;
  }
`;

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 5% 120px;
  background: ${({ bg }) => bg};
  width: 100%;
  box-sizing: border-box;
  
  > * {
    max-width: ${CONTAINER_WIDTH};
  }
  
  ${media.laptop`
    padding: 150px 5% 90px;
    
    > * {
      max-width: ${CONTAINER_LAPTOP_WIDTH};
    }
  `}
  
  ${media.tablet`
    padding: 120px 5% 100px;
  `}
  
  ${media.mobile`
    padding: 80px 5% 60px;
  `}
`;

Container.defaultProps = {
  bg: 'white',
};
