import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../buttons/roundedButton';
import { BLACK, BLUE, WHITE } from '../../constants/colors';
import { LAPTOP, TABLET } from '../../constants/screens';

const QuestionItems = ({ content }) => (
  <QuestionItemsContainer>
    <QuestionIconsRow>
      {
        content.map(({ id, icon: { file: { localFile: { publicURL } } } }) => (
          <QuestionIcon key={id}>
            <img src={publicURL} alt="" />
          </QuestionIcon>
        ))
        }
    </QuestionIconsRow>
    <QuestionItemTitleRow>
      {
        content.map(({ id, title }) => (
          <QuestionItemTitle key={id + 1}>
            <Title>
              {title}
            </Title>
          </QuestionItemTitle>
        ))
      }
    </QuestionItemTitleRow>
    <QuestionItemTextRow>
      {
        content.map(({ id, description: { html } }) => (
          <QuestionItemText key={id + 2}>
            <Text dangerouslySetInnerHTML={{ __html: html }} />
          </QuestionItemText>
        ))
      }
    </QuestionItemTextRow>
    <QuestionItemButtonsRow>
      {
        content.map(({ id, button: { text, url } }) => (
          <QuestionItemButton key={id + 3}>
            <Button
              text={text}
              color={BLUE}
              hoverTextColor={WHITE}
              tag="a"
              href={url}
            />
          </QuestionItemButton>
        ))
      }
    </QuestionItemButtonsRow>
  </QuestionItemsContainer>
);

const QuestionItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: ${TABLET}) {
   display: none;
  }
`;

const QuestionIconsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 75px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 50px;
  }
`;

const QuestionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
  
  > img {
    height: 120px;
    max-width: 100%;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    > img {
      height: 90px;
    }
  }
`;

const QuestionItemTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 35px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 25px;
  }
`;

const QuestionItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
`;

const Title = styled.h4`
  font-size: 33px;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${BLUE};
  max-width: 385px;
  line-height: normal;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 24px;
  }
`;

const QuestionItemTextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 40px;
  
  @media screen and (max-width: ${LAPTOP}) {
    margin-bottom: 30px;
  }
`;

const QuestionItemText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0.3;
`;

const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${BLACK};
  max-width: 390px;
  width: 100%;
  line-height: normal;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 17px;
  }
`;

const QuestionItemButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const QuestionItemButton = styled.div`
  display: flex;
  flex: 0.3;
  justify-content: center;
  align-items: center;
`;

QuestionItems.propTypes = {
  content: PropTypes.instanceOf(Array).isRequired,
};

export default QuestionItems;
