import React from 'react';
import styled from 'styled-components';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import Arrow from '../../svg/arrow-dropdown.svg';
import { WHITE, TURQUOISE } from '../../constants/colors';
import { MOBILE } from '../../constants/screens';

const renderDropdown = (tabs, onClick, currentTab) => (
  <Dropdown>
    {
      tabs.map(({
        node: {
          buttonIcon: { file: { localFile: { publicURL, svgIcon } } },
          name,
        },
      }, index) => (index === currentTab
        ? null : (
          <DropdownContent onClick={() => onClick(index)} key={name}>
            <Content>
              <IconWrapper>
                {publicURL ? <img src={publicURL} alt="" /> : svgIcon}
              </IconWrapper>
              {name}
            </Content>
          </DropdownContent>
        )))
    }
  </Dropdown>
);

class CaseDropdown extends React.Component {
  constructor() {
    super();
    this.state = { showDropdown: false };
  }

  handleDropdown = () => {
    const { showDropdown } = this.state;
    this.setState({ showDropdown: !showDropdown });
  }

  handleClickOutside = () => {
    this.setState({ showDropdown: false });
  }

  handleUnactiveClick = (index) => {
    this.setState({ showDropdown: false });
    const { onClick } = this.props;
    onClick(index);
  }

  render() {
    const { showDropdown } = this.state;
    const currentArrow = showDropdown ? <WhiteArrowUp /> : <WhiteArrowDown />;

    const {
      currentTab,
      tabs,
    } = this.props;
    const { node: currentTabNode = {} } = tabs[currentTab];

    const { buttonIcon: { file: { localFile: { publicURL, svgIcon } } }, name } = currentTabNode;

    return (
      <DropdownWrapper>
        <Container onClick={this.handleDropdown} collapsed={!showDropdown}>
          <Content>
            <IconWrapper>
              { publicURL ? <img src={publicURL} alt="" /> : svgIcon }
            </IconWrapper>
            {name}
          </Content>
          {currentArrow}
        </Container>
        {showDropdown && renderDropdown(tabs, this.handleUnactiveClick, currentTab)}
      </DropdownWrapper>
    );
  }
}

CaseDropdown.propTypes = {
  tabs: PropTypes.instanceOf(Array),
  currentTab: PropTypes.number,
  onClick: PropTypes.func,
};

CaseDropdown.defaultProps = {
  tabs: [{ node: { buttonIcon: { file: { localFile: { publicURL: '' } } }, name: '' } }],
  onClick: () => null,
  currentTab: 0,
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${WHITE};
  width: 100%;
  height: 85px;
  border-radius: ${props => (props.collapsed ? '40px' : 0)};
  cursor: pointer;
  font-size: 30px;
  font-family: BrandonGrotesque;
  text-transform: uppercase;
  background-color: ${TURQUOISE};
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px 0 10px;
`;

const IconWrapper = styled.div`
  > img {
    filter: brightness(0) invert(1);
  }
  
  > img, svg {
    width: 45px;
    height: 45px;
    margin-right: 20px;
    margin-left: 60px;
    
    @media screen and (max-width: ${MOBILE}) {
      width: 25px;
      height: 25px;
      margin: 0 20px;
    }

    path {
      fill: ${WHITE};
    }
  }
`;

const WhiteArrowDown = styled(Arrow)`
  path {
    fill: ${WHITE};
  }
  margin-right: 20px;
  
  @media screen and (max-width: ${MOBILE}) {
    width: 20px;
    flex-shrink: 0;
  }
`;

const WhiteArrowUp = styled(WhiteArrowDown)`
  transform: rotate(180deg);
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  box-shadow: 0px 10px 19.1px 1.9px rgba(42, 21, 154, 0.64);
  border-radius: 40px;
  max-width: 400px;
  width: 100%;
  position: relative;
  z-index: 1;
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 18px;
    width: 250px;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 85px;
  width: 100%;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background-image: linear-gradient(139deg, #2a159a, #2a159a 31%, #6b27e8);
  box-shadow: 0px 10px 19.1px 1.9px rgba(42, 21, 154, 0.64);
  
  > div:last-child {
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }
`;

const DropdownContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${WHITE};
  width: 100%;
  font-family: BrandonGrotesque;
  height: 85px;
  cursor: pointer;
  font-size: 30px;
  text-transform: uppercase;
  background-color: transparent;
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  }
  
  :hover {
    background-color: rgba(59, 205, 213, 0.5);
  }
`;

export default onClickOutside(CaseDropdown);
