import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import ShowCaseButtonHome from './showCaseButtonHome';
import { LAPTOP, TABLET } from '../../constants/screens';

const ShowCaseButtonsContainerHome = ({
  tabs,
  background,
  className,
  currentTab,
  onClick,
}) => (
  <ButtonsWrapper className={className}>
    <Background>
      <Content>
        <BgImage sizes={background} />
        {
          tabs.map((tab, i) => (
            <ShowCaseButtonHome
              key={tab.node.name}
              icon={tab.node.buttonIcon}
              name={tab.node.name}
              isActive={i === currentTab}
              onClick={() => onClick(i)}
            />
          ))
        }
      </Content>
    </Background>
  </ButtonsWrapper>
);

ShowCaseButtonsContainerHome.propTypes = {
  tabs: PropTypes.instanceOf(Array).isRequired,
  background: PropTypes.shape().isRequired,
  className: PropTypes.string,
  currentTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
};

ShowCaseButtonsContainerHome.defaultProps = {
  currentTab: null,
  onClick: () => null,
  className: '',
};

const BgImage = styled(Img)`
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0; 
  left: 0;
  margin-bottom: 0 !important;
  object-fit: contain;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.4;
  background-image: linear-gradient(139deg, #2a159a, #2a159a 31%, #6b27e8);
  min-width: 300px;
  padding: 0 20px;
  
  @media screen and (max-width: ${TABLET}) {
    align-self: stretch;
  }
`;

const Background = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: center;
  
  @media screen and (max-width: ${TABLET}) {
    background: transparent;
  }
`;

const Content = styled.div`
  position: relative;
  max-height: 580px;
  padding: 0 50px 0 0;
  height: 100%;
  justify-content: center;
  
  > div:not(:last-child) {
    margin-bottom: 50px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10% 0;
  }
  
  @media screen and (min-width: ${LAPTOP}) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: ${LAPTOP}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  @media screen and (max-width: 400px) {
    align-items: unset;
  }
`;

export default ShowCaseButtonsContainerHome;
