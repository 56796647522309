import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import './theme.css';
import { omit } from 'ramda';
import Next from '../../../svg/slider-next-arrow.svg';
import Prev from '../../../svg/slider-prev-arrow.svg';
import { DARK_BLUE, TURQUOISE } from '../../../constants/colors';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP,
} from '../../../constants/screens';

const ArrowComponent = (props) => {
  const { Arrow, ...restProps } = props;

  return <Arrow {...omit(['currentSlide', 'slideCount'], restProps)} />;
};

ArrowComponent.propTypes = {
  Arrow: PropTypes.shape().isRequired,
};

class Index extends React.Component {
  componentDidMount() {
    const { getSliderRef } = this.props;
    if (this.slider && getSliderRef) {
      getSliderRef(this.slider);
    }
  }

  prevArrowClick = () => {
    if (this.slider) {
      this.slider.slickPrev();
    }

    const { onArrowClick } = this.props;
    onArrowClick();
  };

  nextArrowClick = () => {
    if (this.slider) {
      this.slider.slickNext();
    }

    const { onArrowClick } = this.props;
    onArrowClick();
  };

  render() {
    const {
      nextArrow,
      prevArrow,
      children,
      className,
      title,
      showCustomArrows,
      ...sliderProps
    } = this.props;

    return (
      <CarouselContent className={className}>
        <MainContent>
          <CarouselTitle>
            {title}
          </CarouselTitle>
          <SliderWrapper>
            {showCustomArrows && <PrevArrow onClick={this.prevArrowClick} />}
            <Slider
              ref={(slider) => { this.slider = slider; }}
              {...sliderProps}
              /* eslint-disable no-use-before-define */
              nextArrow={<ArrowComponent Arrow={nextArrow || NextArrow} />}
              prevArrow={<ArrowComponent Arrow={prevArrow || PrevArrow} />}
              /* eslint-enable no-use-before-define */
            >
              {children}
            </Slider>
            {showCustomArrows && <NextArrow onClick={this.nextArrowClick} />}
          </SliderWrapper>
        </MainContent>
      </CarouselContent>
    );
  }
}

Index.propTypes = {
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
  nextArrow: PropTypes.func,
  prevArrow: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  showCustomArrows: PropTypes.bool,
  onArrowClick: PropTypes.func,
  getSliderRef: PropTypes.func,
};

Index.defaultProps = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: null,
  prevArrow: null,
  onArrowClick: () => {},
  title: '',
  className: '',
  showCustomArrows: false,
  getSliderRef: () => null,
};

const CarouselContent = styled.div`
  background-color: ${DARK_BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 135px 5% 35px 5%;
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5% 35px 5%;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
`;

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  .slick-slider {
    width: 80%;
    
    > .slick-list {
      > .slick-track {
        > .slick-slide {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 400px;
          
          @media (max-width: ${LAPTOP}) {
            min-height: 330px;
          }
          
          @media (max-width: ${MOBILE}) {
            min-height: 500px;
          }
          
          > div {
            display: flex;
          }
        }
      }
    }
    
    > .slick-dots {
      > li > button:before {
          color: white;
          font-size: 25px;
        }
      }
      
    .slick-active > button:before {
        color: white;
        font-size: 25px;
        opacity: 1;
      }
    }
    
    .slick-arrow {
      height: 45px;
      width: 27px;
      
      @media screen and (max-width: 420px) {
        height: 25px;
        width: 15px;
      }
    }
  }
`;

const NextArrow = styled(Next)`
  > path {
    fill: ${TURQUOISE};
  }
`;

const PrevArrow = styled(Prev)`
  > path {
    fill: ${TURQUOISE};
  }
`;

const CarouselTitle = styled.h4`
  font-size: 33px;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${TURQUOISE};
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 26px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    font-size: 24px;
  }
`;

export default Index;
