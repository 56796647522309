import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ShowCaseButtonsContainerHome from './showCaseButtonsContainerHome';
import Case from './case';
import CaseDropdown from '../common/caseDropdown';
import { TABLET, media, LAPTOP } from '../../constants/screens';
import { WHITE } from '../../constants/colors';

class ShowCaseHome extends React.Component {
  constructor() {
    super();
    this.state = { currentTab: 0 };
  }

  handleOnClick = (currentTab) => {
    this.setState({ currentTab });
  }

  render() {
    const { buttonsBackground, tabs } = this.props;
    const { currentTab } = this.state;

    const { node: currentTabNode } = tabs[currentTab];

    return (
      <ShowCaseContainer>
        <ShowCaseButtonsContainerHome
          tabs={tabs}
          background={buttonsBackground}
          currentTab={currentTab}
          onClick={this.handleOnClick}
        />
        <ContentWrapper>
          <CaseDropdownWrapper>
            <CaseDropdown
              tabs={tabs}
              currentTab={currentTab}
              onClick={this.handleOnClick}
            />
          </CaseDropdownWrapper>
          <Case
            currentCaseData={currentTabNode}
          />
          <ColoredContainer />
        </ContentWrapper>
      </ShowCaseContainer>
    );
  }
}

const ShowCaseContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
    
    > div:first-child {
      display: none;
    }
  }
`;

const ContentWrapper = styled.div`
  flex: 0.65;
  background-color: ${WHITE};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 110px 0; 
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 80px 0;
  }
  
  @media screen and (max-width: ${TABLET}) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0;
    position: relative;
  }
`;

const ColoredContainer = styled.div`
  width: 100%;
  height: 430px;
  background-color: ${WHITE};
  display: none;
  
  @media screen and (max-width: 710px) {
    height: 450px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    display: block;
  }
  
  ${media.mobile`
    height: 155px;
  `}
`;

const CaseDropdownWrapper = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(139deg, #2a159a, #2a159a 31%, #6b27e8);
  padding: 70px 0 300px;
  
  @media screen and (max-width: ${TABLET}) {
    display: flex;
  }
`;

ShowCaseHome.propTypes = {
  buttonsBackground: PropTypes.shape().isRequired,
  tabs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ShowCaseHome;
