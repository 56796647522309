import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { WHITE } from '../../constants/colors';
import { media } from '../../constants/screens';
import { addOpacity, getPropertyValue } from '../../utils/component';

const buttonStyle = props => css`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid ${props.disabled ? addOpacity(props.color, 0.2) : props.color};
  color: ${props.colorWithoutHover || props.color};
  width: auto;
  min-width: 280px;
  min-height: 75px;
  border-radius: 40px;
  cursor: pointer;
  font-family: AvenirNextLTProBold;
  font-size: 19px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background-color: ${getPropertyValue([
    { key: 'disabled', true: props.background ? addOpacity(props.background, 0.5) : 'transparent' },
    { key: 'background', true: props.background },
  ], 'transparent', props)};
  box-sizing: border-box;
  padding: 0 30px;
  transition: all 0.2s;
  align-self: center;
  
  ${media.laptop`
    min-width: 230px;
    font-size: 16px;
    min-height: 60px;
    border-radius: 30px;
  `};
  
  ${media.mobile`
    font-size: 14px;
 `}

  &:hover{
    background-color: ${!props.withoutHover && props.color};
    color: ${!props.withoutHover && props.hoverTextColor};
    cursor: ${props.disabled ? 'default' : 'pointer'};
  }
`;

const RoundedButton = ({
  text,
  color,
  hoverTextColor,
  className,
  withoutHover,
  background,
  colorWithoutHover,
  disabled,
  tag,
  ...restProps
}) => {
  const Wrapper = styled(tag)`${props => buttonStyle(props)}`;

  return (
    <Wrapper
      color={color}
      hoverTextColor={hoverTextColor}
      className={className}
      withoutHover={withoutHover}
      background={background}
      colorWithoutHover={colorWithoutHover}
      disabled={disabled}
      {...restProps}
    >
      {text}
    </Wrapper>
  );
};

RoundedButton.defaultProps = {
  hoverTextColor: WHITE,
  tag: 'button',
  withoutHover: false,
  colorWithoutHover: '',
  className: '',
  background: '',
  disabled: false,
};

RoundedButton.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  hoverTextColor: PropTypes.string,
  withoutHover: PropTypes.bool,
  colorWithoutHover: PropTypes.string,
  className: PropTypes.string,
  background: PropTypes.string,
  disabled: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.shape({})]),
};

export default RoundedButton;
