import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import {
  CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, TABLET, media, LAPTOP,
} from '../../constants/screens';
import { WHITE } from '../../constants/colors';
import Info from '../common/info';

const AboutUsHomePage = ({ sections }) => (
  <AboutUsHomePageContainer>
    <MainContent>
      {
          sections.map((
            {
              image, id, content: { 0: { lead, title, description: { html } } },
            }, i,
          ) => (
            <AboutUsItem
              key={id}
              image={image.file.localFile.childImageSharp.sizes}
              imgAltAttr={image.alt}
              reversed={!!(i % 2)}
              maxImageWidth="600px"
            >
              <Info
                title={title}
                subTitle={lead}
                text={html}
                reversed={!!(i % 2)}
              />
            </AboutUsItem>
          ))
        }
    </MainContent>
  </AboutUsHomePageContainer>
);

const AboutUsHomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  padding: 175px 5% 145px 5%;
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 130px 5% 100px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5% 75px 5%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  
  > div:not(:last-child) {
    margin-bottom: 100px;
  }
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
    
    & > div:not(:last-child) {
      margin-bottom: 70px;
    }
  `};
`;

AboutUsHomePage.propTypes = {
  sections: PropTypes.instanceOf(Array).isRequired,
};

export default AboutUsHomePage;
