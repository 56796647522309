import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { TURQUOISE } from '../../constants/colors';
import {
  CONTAINER_LAPTOP_WIDTH,
  CONTAINER_WIDTH, MOBILE, TABLET, media, LAPTOP,
} from '../../constants/screens';

const text = '/World\'s leading tech companies trust our insights to build developer-first strategies';

const Clients = ({ background, clients }) => (
  <ClientsWrapper>
    <ClientsContainer
      background={background}
    >
      <ClientsContent>
        <ClientsText>{text}</ClientsText>
        <ClientsCompanies>
          {clients.map(c => <Image sizes={c.logo.localFile.childImageSharp.sizes} key={c.id} alt="our client" />)}
        </ClientsCompanies>
      </ClientsContent>
    </ClientsContainer>
  </ClientsWrapper>
);

const ClientsWrapper = styled.div`
  background-image: linear-gradient(145deg, #2a159a, #2b55c5);
  position: relative;
`;

const ClientsContainer = styled.div`
  background: url(${props => props.background}) no-repeat;
  background-size: 100%;
  background-position: center;
  align-items: center;
  justify-content: center;
  padding: 205px 5% 145px;
  max-width:  ${CONTAINER_WIDTH};
  margin: auto;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
    padding: 150px 5% 100px;
  `};
  
  @media screen and (max-width: ${TABLET}) {
    padding: 75px 5%;
  }
`;

const ClientsContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ClientsText = styled.h4`
  font-size: 40px;
  text-align: center;
  color: ${TURQUOISE};
  text-transform: uppercase;
  max-width: 1050px;
  margin-bottom: 140px;
  line-height: normal;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 30px;
    margin-bottom: 100px;
  }
  
  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 70px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const ClientsCompanies = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  
  > div {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: calc(20% - 40px);
    margin: 20px;
    
    
    @media screen and (max-width: ${TABLET}) {
      flex-basis: calc(25% - 10px);
      margin: 20px 5px;
    }
    
    @media screen and (max-width: 650px) {
      height: 50px;
    }

    & img {
      width: 100%;
      filter: brightness(0%) invert(100%);
    }
  }
`;

Clients.propTypes = {
  background: PropTypes.string.isRequired,
  clients: PropTypes.instanceOf(Array),
};

Clients.defaultProps = {
  clients: [],
};

export default Clients;
