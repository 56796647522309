import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../buttons/roundedButton';
import { BLACK, BLUE, WHITE } from '../../constants/colors';
import { MOBILE, TABLET } from '../../constants/screens';

const QuestionItem = ({
  image, title, text, buttonText, to,
}) => (
  <QuestionItemContainer>
    <QuestionItemIcon>
      <img src={image} alt="" />
    </QuestionItemIcon>
    <QuestionItemTextContainer>
      <QuestionItemTitle>
        {title}
      </QuestionItemTitle>
      <QuestionItemText dangerouslySetInnerHTML={{ __html: text }} />
    </QuestionItemTextContainer>
    <Button
      text={buttonText}
      url={to}
      tag="a"
      color={BLUE}
      hoverTextColor={WHITE}
    />
  </QuestionItemContainer>
);

const QuestionItemContainer = styled.div`
  display: none;
  flex-direction: column;
  flex: 0.3;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;

  @media screen and (max-width: ${TABLET}) {
    display: flex;
    margin-bottom: 0;
  }
`;

const QuestionItemIcon = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  max-height: 120px;
  height: 100%;
  width: 126px;
  margin-bottom: 75px;

  @media screen and (max-width: ${TABLET}) {
    margin-bottom: 35px;
  }

  > img {
    max-height: 100%;
    object-fit: contain;
    max-width: 100%;
  }
`;

const QuestionItemTitle = styled.h4`
  font-size: 33px;
  font-weight: 900;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  color: ${BLUE};
  margin-bottom: 35px;

  @media screen and (max-width: ${TABLET}) {
    max-width: 450px;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 24px;
  }
`;

const QuestionItemText = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 21px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: ${BLACK};

  @media screen and (max-width: ${TABLET}) {
    max-width: 500px;
  }

  @media screen and (max-width: ${MOBILE}) {
    font-size: 19px;
  }
`;

const QuestionItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  flex: 1;

  @media screen and (max-width: ${TABLET}) {
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }

`;

QuestionItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default QuestionItem;
