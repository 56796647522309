import React from 'react';
import { LayoutContext } from '../components/layout';

const defaultOptions = {
  margin: 100,
  isBlog: false,
};

export default function (options = {}) {
  return function WithMovingHeaderHOC(WrappedComponent) {
    const { margin, isBlog } = { ...defaultOptions, ...options };

    class WithMovingHeader extends React.Component {
      componentDidMount() {
        const { layoutUpdater } = this.context;
        this.updateLayout();
        window.addEventListener('scroll', this.updateLayout);
        layoutUpdater({ isBlog });
      }

      componentWillUnmount() {
        const { layoutUpdater } = this.context;
        layoutUpdater({ fixHeader: false });
        window.removeEventListener('scroll', this.updateLayout);
      }

      updateLayout = () => {
        const threshold = 150;
        const { layoutUpdater } = this.context;
        let { fixHeader } = this.context;

        if (window.pageYOffset > threshold) {
          fixHeader = true;
        } else if (threshold - window.pageYOffset >= margin) {
          fixHeader = false;
        }

        // eslint-disable-next-line
        if (fixHeader !== this.context.fixHeader) {
          layoutUpdater({ fixHeader });
        }
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
          />
        );
      }
    }

    WithMovingHeader.contextType = LayoutContext;

    return WithMovingHeader;
  };
}
