import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { MOBILE, TABLET } from '../../constants/screens';

const AboutUsItem = ({
  reversed,
  image,
  children,
  className,
  maxImageWidth,
  imgAltAttr,
}) => (
  <AboutUsItemContainer className={className}>
    <AboutUsItemInfoWrapper order={reversed ? 2 : 1}>
      {children}
    </AboutUsItemInfoWrapper>
    <AboutUsItemImageContainer order={reversed ? 1 : 2} maxImageWidth={maxImageWidth}>
      <Image sizes={image} alt={imgAltAttr} />
    </AboutUsItemImageContainer>
  </AboutUsItemContainer>
);

const AboutUsItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
  }
`;

export const AboutUsItemImageContainer = styled.div`
  display: flex;
  flex: 0.45;
  order: ${props => props.order};
  width: 100%;
  max-width: ${props => props.maxImageWidth};
  
  @media screen and (max-width: ${TABLET}) {
    max-width: 450px;
    order: 2;
  }
  
  > div {
    width: 100%;
  }
`;

export const AboutUsItemInfoWrapper = styled.div`
  display: flex;
  flex: 0.4;
  order: ${props => props.order};
  
  @media screen and (max-width: ${TABLET}) {
    order: 1;
    margin-bottom: 100px;
  }
  
  @media screen and (max-width: ${MOBILE}) {
    order: 1;
    margin-bottom: 60px;
  }
`;

AboutUsItem.propTypes = {
  reversed: PropTypes.bool,
  image: PropTypes.shape().isRequired,
  maxImageWidth: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  imgAltAttr: PropTypes.string,
};

AboutUsItem.defaultProps = {
  reversed: false,
  maxImageWidth: '',
  className: '',
  imgAltAttr: '',
};

export default AboutUsItem;
