import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { WHITE } from '../../constants/colors';
import { LAPTOP, MOBILE } from '../../constants/screens';

const CarouselContentItem = ({ text, clientName }) => (
  <ItemWrapper>
    <TextWrapper dangerouslySetInnerHTML={{ __html: text }} />
    <Client>
      {clientName}
    </Client>
  </ItemWrapper>
);

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 5%;
  max-width: 800px;
  width: 100%;
`;

const Client = styled.p`
  font-family: AvenirNextLTProBoldIt;
  font-size: 26px;
  font-stretch: normal;
  line-height: normal;
  text-align: center;
  color: ${WHITE};
  line-height: normal;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 22px;
  }
 
  @media screen and (max-width: ${MOBILE}) {
    font-size: 20px;
  }
`;

const TextWrapper = styled.div`
  p {
    font-family: AvenirNextLTProIt;
    font-size: 26px;
    font-stretch: normal;
    line-height: normal;
    text-align: center;
    color: ${WHITE};
    line-height: normal;
    margin-bottom: 30px;
    
    @media screen and (max-width: ${LAPTOP}) {
      font-size: 22px;
   }
    
    @media screen and (max-width: ${MOBILE}) {
      font-size: 20px;
    }
  }
`;


CarouselContentItem.propTypes = {
  text: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
};

export default CarouselContentItem;
