import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { getElementByPath } from '../utils/dom';
import { parseQuery } from '../utils/common';
import WithLocation from './withLocation';

const defaultOptions = { sections: {}, ref: null };

/**
 * HOC designed to controll window scroll
 * position over wrapped component
 *
 * @param options.sections object with section name and dom reference declaration
 * @param options.ref custom reference if we need to specify custom DOM node startpoint
 * it is wrapped component by default
 *
 * "section" is DOM nodes path to particular element
 * for example value [ 0, 1 ] it is second child element of the first
 * child from wrapped component if parameter options.ref is not specified
 *
 * wrapped component getting scrollTo() method that receives to params:
 * @param section string definition of predefined section
 * @param options options for Element.scrollIntoView() method
 *
 * HOC also moves scroll position after component mount by getting
 * section name from query parameters to handle direct link user page entrance
 * for that reason WE SHOULD AVOID CAMELCASE in section name definition
 *
*/


export default function (options = {}) {
  const { sections } = { ...defaultOptions, ...options };

  return function WithScrollHOC(WrappedComponent) {
    class WithScroll extends React.Component {
      componentDidMount() {
        const { location: { search } } = this.props;
        this.timer = setTimeout(() => {
          const query = parseQuery(search);
          if (query.section) {
            this.handleScroll(query.section);
          }
        }, 0);
      }

      // componentWillReceiveProps({ location: { search: nextSearch } }) {
      //   const { location: { search } } = this.props;
      //
      //   if (search !== nextSearch) {
      //     this.timer = setTimeout(() => {
      //       const nextQuery = parseQuery(nextSearch);
      //       const query = parseQuery(search);
      //       if (nextQuery.section && (nextQuery.section !== query.section)) {
      //         this.handleScroll(nextQuery.section);
      //       }
      //     }, 200);
      //   }
      // }

      componentWillUnmount() {
        clearTimeout(this.timer);
      }

      // eslint-disable-next-line
      handleScroll = (section, options = { block: 'start', behavior: 'smooth' }) => {
        if (!this.ref) throw new Error('[WithScroll enhancer] ref is not defined');

        const path = sections[section];

        if (path) {
          const elem = getElementByPath(path, this.ref);
          elem.scrollIntoView(options);
        }
      }

      getRef = () => {
        if (options.ref === 'body') {
          return document.body;
        }
        return null;
      }

      render() {
        return (
          <WrappedComponent
            // eslint-disable-next-line
            ref={ref => options.ref ? this.ref = this.getRef() : this.ref = findDOMNode(ref)}
            scrollTo={this.handleScroll}
            {...this.props}
          />
        );
      }
    }

    WithScroll.propTypes = {
      location: PropTypes.shape(),
    };

    WithScroll.defaultProps = {
      location: { search: '' },
    };

    return WithLocation(WithScroll);
  };
}
