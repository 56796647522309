import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import Info from '../common/info';
import {
  LAPTOP, TABLET, media, CONTAINER_WIDTH, CONTAINER_LAPTOP_WIDTH,
} from '../../constants/screens';
import { WHITE, TURQUOISE } from '../../constants/colors';
import { Container } from '../typography';

const Introduction = ({
  image: { file: { localFile: { childImageSharp: { sizes } } } },
  content: {
    0: {
      title, lead, description: { html }, button,
    },
  },
}) => (
  <IntroductionContainer>
    <MainContent>
      <IntroductionInfoWrapper>
        <Info
          pageName="index"
          title={lead}
          subTitle={title}
          text={html}
          titleColor={TURQUOISE}
          subTitleFontSize="40px"
          subTitleColor={WHITE}
          textColor={WHITE}
          withButton
          buttonText={button.text}
          link={button.url}
        />
      </IntroductionInfoWrapper>
      <IntroductionImageContainer>
        <Image sizes={sizes} />
      </IntroductionImageContainer>
    </MainContent>
  </IntroductionContainer>
);

const IntroductionContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 300px 5% 0 5%;
  background-image: linear-gradient(126deg, #2a159a, #2a159a 46%, #2b55c5);
  
  @media screen and (max-width: ${TABLET}) {
    padding: 200px 5% 0 5%;
  }
  
  @media screen and (max-width: ${LAPTOP}) {
    padding: 225px 5% 0 5%;
  }
`;

const IntroductionImageContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 820px;
  margin-right: -150px;
  bottom: 0;
  right: 80px;
  
  & > div {
    width: 100%;
  }
  
  ${media.laptop`
    margin-right: -100px;
    max-width: 550px;
  `}
  
  ${media.tablet`
    margin-right: 0;
    max-width: 600px;
  `}
`;

const IntroductionInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 240px;
  margin-right: 100px;
  
  ${media.laptop`
    margin-bottom: 160px;
  `}
  
  ${media.tablet`
    margin-right: 0;
    margin-bottom: 100px;
  `}
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  @media screen and (max-width: ${TABLET}) {
    flex-direction: column;
    align-items: center;
  }
`;

Introduction.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  image: PropTypes.shape().isRequired,
};

export default Introduction;
