import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { compose, pathOr } from 'ramda';
import { graphql } from 'gatsby';
import Subscribe from '../components/homePage/subscribe';
import AboutUsHomePage from '../components/homePage/aboutUsHomePage';
import Clients from '../components/homePage/clients';
import Introduction from '../components/homePage/introduction';
import Questions from '../components/homePage/questions';
import Index from '../components/common/carousel';
import withCustomLayout from '../enhancers/withCustomLayout';
import { LIGHT_SCHEME } from '../constants/colors';
import ShowCaseHome from '../components/homePage/showCaseHome';
import CarouselContentItem from '../components/homePage/carouselContentItem';
import withStickyHeader from '../enhancers/withStickyHeader';
import withScroll from '../enhancers/withScroll';

const HomePage = (props) => {
  const { data } = props;

  const {
    testimonials: { edges: testimonials },
    clients: { edges: clients },
    page: { introduction, sections },
    tabs: { edges: tabs },
  } = data;

  const aboutUsSections = sections.filter(({ meta }) => meta.includes('about us'));
  const questions = sections.filter(({ meta }) => meta.includes('question'));

  const carouselItems = testimonials.map(({ node }) => ({
    id: node.id,
    text: pathOr('', ['content', 'html'], node),
    clientName: `${node.name}, ${pathOr('', ['role'], node)}`,
  })).filter(t => !!t.text);

  return (
    <Page>
      <Introduction
        {...introduction}
      />
      <AboutUsHomePage sections={aboutUsSections} />
      <Clients
        background={data.clientsBackground.childImageSharp.original.src}
        clients={clients.map(c => c.node)}
      />
      <Index
        title="/hear it from our clients"
      >
        {
          carouselItems.map(item => (
            <CarouselContentItem key={item.id} text={item.text} clientName={item.clientName} />
          ))
        }
      </Index>
      <Questions questions={questions} />
      <ShowCaseHome
        tabs={tabs}
        buttonsBackground={data.caseBackground.childImageSharp.sizes}
      />
      <Subscribe image={data.subscribeImage.childImageSharp.sizes} />
    </Page>
  );
};

const Page = styled.div`
  overflow-x: hidden;
`;

HomePage.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default compose(
  withScroll({ sections: { partners: [4] } }),
  withStickyHeader(),
  withCustomLayout({ scheme: LIGHT_SCHEME }),
)(HomePage);

// eslint-disable-next-line
export const query = graphql`
  query GetHomePageData {
    subscribeImage: file(relativePath: { regex: "/subscribe-home.png/" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
    clientsBackground: file(relativePath: { regex: "/clients-background.png/" }) {
      childImageSharp {
        original {
          src
        }
      }
    }
    clientIcons: file(relativePath: { regex: "/clients.png/" }) {
      childImageSharp {
        sizes(maxWidth: 820) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
    caseBackground: file(relativePath: { regex: "/showCasesHomeBg.png/" }) {
      childImageSharp {
        sizes(maxWidth: 500) {
          ...GatsbyImageSharpSizes_noBase64
        }
      }
    }
    testimonials: allTestimonial {
      edges {
        node {
          id
          name
          role
          content {
            html
          }
        }
      }
    }
    clients: allClient {
      edges {
        node {
          id
          name
          logo {
            localFile {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
    }
    page(url: { eq: "/" }) {
      url
      introduction {
        image {
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 820) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        content {
          title
          lead
          description {
            html
          }
          button {
            text
            url
          }
        }
      }
      sections {
        id: _id
        name
        meta
        title
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 640) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
        content {
          id: _id
          icon {
            file {
              localFile {
                publicURL
              }
            }
          }
          title
          lead
          button {
            text
            url
          }
          description {
            html
          }
        }
      }
    }
    tabs: allTab(filter: { meta: { regex: "/home/" } }) {
      edges {
        node {
          name
          title
          description {
            html
          }
          link {
            url
            text
          }
          buttonIcon {
            file {
              localFile {
                publicURL
              }
            }
          }
          image {
            file {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 900) {
                    ...GatsbyImageSharpSizes_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
