import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { WHITE, TURQUOISE } from '../../constants/colors';
import { media } from '../../constants/screens';

const ShowCaseButtonHome = ({
  name,
  icon,
  isActive,
  onClick,
}) => (
  <Wrapper active={isActive} onClick={onClick}>
    <IconWrapper>
      <img src={icon.file.localFile.publicURL} alt="" />
    </IconWrapper>
    {name}
  </Wrapper>
);

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  font-family: BrandonGrotesque;
  justify-content: flex-start;
  background-color: transparent;
  position: relative;
  border: none;
  outline: none;
  color: ${WHITE};
  max-width: 400px;
  width: 100%;
  min-width: 300px;
  height: 85px;
  border-radius: 40px;
  padding-right: 30px;
  cursor: pointer;
  font-size: 30px;
  text-transform: uppercase;
  background-color: ${props => (props.active ? TURQUOISE : 'transparent')};
  box-shadow: ${props => (props.active ? '0px 10px 19.1px 1.9px rgba(42, 21, 154, 0.64)' : 'none')};
  
  ${media.laptop`
    max-width: 300px;
    min-width: 220px;
    height: 60px;
    border-radius: 30px;
    padding-right: 20px;
    font-size: 22px;
  `};
`;

const IconWrapper = styled.div`
  width: 45px;
  height: 45px;
  margin-right: 20px;
  margin-left: 60px;
  
  > img {
    width: 100%;
    height: 100%;
    filter: brightness(0) invert(1);
  }
  
  ${media.laptop`
    width: 35px;
    height: 35px;
    margin-right: 15px;
    margin-left: 30px;
  `};
`;

ShowCaseButtonHome.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.shape().isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

ShowCaseButtonHome.defaultProps = {
};

export default ShowCaseButtonHome;
