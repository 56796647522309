import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { BLACK, BLUE, WHITE } from '../../constants/colors';
import Arrow from '../../svg/more.svg';
import { LAPTOP, TABLET } from '../../constants/screens';

const Case = ({
  currentCaseData: {
    title,
    link: { url, text },
    description: { html },
    image: { file: { localFile: { childImageSharp: { sizes } } } },
  },
}) => (
  <CaseContainer>
    <ImageContainer>
      <Image sizes={sizes} />
    </ImageContainer>
    <Content>
      <Title>
        {title}
      </Title>
      <Text dangerouslySetInnerHTML={{ __html: html }} />
      <a href={url} target="_blank" rel="noopener noreferrer">
        <LinkContent>
          <LinkText>
            {text}
          </LinkText>
          <Arrow />
        </LinkContent>
      </a>
    </Content>
  </CaseContainer>
);

const CaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 6px 49.1px 4.9px rgba(220, 227, 238, 0.64);
  max-width: 895px;
  margin: 0 110px;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-width: 700px;
    margin: 0 130px;
  }

  @media screen and (max-width: ${TABLET}) {
    background-color: #fff;
    display: block;
    height: auto;
    margin: 0;
    max-width: 700px;
    position: absolute;
    top: 200px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
  }
  
  @media screen and (max-width: 785px) {
    width: 85%;
  }
  
  @media screen and (min-width: 345px) and (max-width: 795px) {
    max-height: 650px;
  }
  
  @media screen and (max-width: 345px) {
    max-height: 680px;
  }
`;

const ImageContainer = styled.div`
  max-height: 365px;
  width: 100%;
  
  @media screen and (max-width: ${LAPTOP}) {
    max-height: 300px;
  }
`;

const Content = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 5% 10%;
  flex: 1 0 auto;
  justify-content: center;

  @media screen and (max-width: ${TABLET}) {
    padding: 7% 10% 10% 10%;
    height: auto;
  }
  
  > a {
    text-decoration: none;
  }
`;

const Image = styled(Img)`
  width: 100%;
  min-width: 500px;
  max-height: 365px;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  
  @media screen and (max-width: ${TABLET}) {
    min-width: 0;
  }
  
  @media screen and (min-width: ${TABLET}) and (max-width: 1507px){
    height: auto;
  }
`;

const Title = styled.h3`
  font-size: 30px;
  line-height: normal;
  color: ${BLACK};
  text-transform: uppercase;
  margin: 0 0 30px 0;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 24px;
    margin: 0 0 20px 0;
  }
`;

const Text = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  color: ${BLACK};
  margin: 0 0 30px 0;
  max-width: 400px;
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 15px;
    margin: 0 0 20px 0;
  }
`;

const LinkContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  > svg {
    width: 15px;
    height: 15px;
    margin-bottom: 4px;
    margin-left: 10px;
    
    circle {
      fill: ${BLUE};
    }
    
    path {
      fill: ${WHITE};
    }
  }
`;

const LinkText = styled.p`
  font-family: AvenirNextLTPro;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  text-transform: uppercase;
  color: ${BLUE};
  
  @media screen and (max-width: ${LAPTOP}) {
    font-size: 12px;
  }
`;

Case.propTypes = {
  currentCaseData: PropTypes.shape().isRequired,
};

export default Case;
